import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const OfferPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    // Check if the user has already seen the offer
    const hasSeenOffer = localStorage.getItem('hasSeenOffer');
    
    // Show the popup only on the /products route and if it hasn't been seen
    if (location.pathname === '/products' && !hasSeenOffer) {
      setIsVisible(true);
      // Set the flag in local storage so the offer won't show again
      localStorage.setItem('hasSeenOffer', 'true');
    }
  }, [location]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleSignUpClick = () => {
    handleClose(); // Close the popup when signing up
  };

  return (
    isVisible && (
      <div className="fixed inset-0 bg-opacity-95 bg-[#1e2022] flex justify-center items-center z-50">
        <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-sm w-full relative">
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-300 hover:text-gray-100"
            aria-label="Close"
          >
            &times; {/* Using a simple 'X' for close button */}
          </button>
          <h2 className="text-2xl font-semibold mb-4 text-white">First time signup offer!</h2>
          <p className="mb-4 text-white text-lg">Sign up through my link to receive coupons and discounts on your orders!</p>
          <a
            className="underline text-3xl max-sm:text-xl text-blue-400 hover:text-blue-300"
            href="https://www.superbuy.com/en/page/login?partnercode=wUr6VT&type=register"
            target="_blank"
            rel="noreferrer"
            onClick={handleSignUpClick} // Call the handleSignUpClick function on click
          >
            <p className='text-center'>Sign Up</p>
          </a>
        </div>
      </div>
    )
  );
};

export default OfferPopup;
